import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  
  about : string; 

  constructor(private router: ActivatedRoute ) { }

  ngOnInit(): void {
    // Get NavigationStart events
    // this.page = this.router.snapshot.queryParams.about;
    this.about = this.router.snapshot.queryParams.about
  }

}
