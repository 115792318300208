import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
// import $ from 'jquery-ui';
declare var $: any;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  constructor() { }
  popup = null;
  ngOnInit(): void {
         $(document).on('click', '.popup', function(){      
            $('.gallery, .popup').removeClass('pop');
            setTimeout(function(){
              $('.popup').remove()
            }, 100);
        });
    // var popup = {
    //   init: function(){
    //     $('figure').click(function(){
    //       popup.open($(this));
    //     });
        
    //     $(document).on('click', '.popup img', function(){
    //       return false;
    //     }).on('click', '.popup', function(){
    //       popup.close;
    //     })
    //   },
    //   open: function(figure) {
    //     console.log(typeof figure);
    //     $('.gallery').addClass('pop');
    //     popup = $('<div class="popup" />').appendTo($('body'));
    //     let fig = figure.clone().appendTo($('.popup'));
    //     let bg = $('<div class="bg" />').appendTo($('.popup'));
    //     let close = $('<div class="close"><svg><use xlink:href="#close"></use></svg></div>').appendTo(fig);
    //     let shadow = $('<div class="shadow" />').appendTo(fig);
    //     let src = $('img', fig).attr('src');
    //     shadow.css({backgroundImage: 'url(' + src + ')'});
    //     bg.css({backgroundImage: 'url(' + src + ')'});
    //     setTimeout(function(){
    //       $('.popup').addClass('pop');
    //     }, 10);
    //   },
    //   close: function(){
    //     $('.gallery, .popup').removeClass('pop');
    //     setTimeout(function(){
    //       $('.popup').remove()
    //     }, 100);
    //   }
    // }
    
    // popup.init()
  }

  openFigure(_figure){
      _figure = $(_figure);
      $('.gallery').addClass('pop');
      this.popup = $('<div class="popup" (click)="closeFigure()" />').appendTo($('body'));
      let fig = _figure.clone().appendTo($('.popup'));
      let bg = $('<div class="bg" />').appendTo($('.popup'));
      let close = $('<div class="close"><svg><use xlink:href="#close"></use></svg></div>').appendTo(fig);
      let shadow = $('<div class="shadow" />').appendTo(fig);
      let src = $('img', fig).attr('src');
      shadow.css({backgroundImage: 'url(' + src + ')'});
      bg.css({backgroundImage: 'url(' + src + ')'});
      setTimeout(function(){
        $('.popup').addClass('pop');
      }, 10);
  }

  closeFigure(){
  }
}
