import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { GalleryComponent } from './gallery.component';
import { CarouselComponent } from './carousel/carousel.component';

@NgModule({
  declarations: [
    GalleryComponent,
    CarouselComponent
  ],
  exports: [
    CarouselComponent
  ],
  imports: [
      CommonModule,
      BrowserModule,
      NgbModule,
      FormsModule,
      RouterModule
  ],
  providers: [],
  bootstrap: [GalleryComponent]
})
export class GalleryModule { }