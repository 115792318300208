import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
// import $ from 'jquery-ui';
declare var $: any;
import 'flexslider';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.css']
})
export class SingleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // $(window).load(function(){
          $( "#slider-range" ).slider({
            range: true,
            min: 0,
            max: 100000,
            values: [ 10000, 60000 ],
            slide: function( event, ui ) {  $( "#amount" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
            }
          });

          $( "#amount" ).val( "$" + $( "#slider-range" ).slider( "values", 0 ) + " - $" + $( "#slider-range" ).slider( "values", 1 ) );

          $('.flexslider').flexslider({
            animation: "slide",
            controlNav: "thumbnails"
          });
    // });      
  }

}
