import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';


// Backend
import { BackendComponent } from './backend/backend.component';
import { HomeComponent } from './backend/component/home/home.component';
import { ProfileComponent } from './backend/component/profile/profile.component';
import { SignupComponent } from './backend/component/signup/signup.component';
import { LandingComponent } from './backend/component/landing/landing.component';
import { LoginComponent } from './backend/component/login/login.component';


// Frontend
import { FrontendComponent } from './frontend/frontend.component';
import { FrontendHomeComponent } from './frontend/component/home/home.component';
import { MailComponent } from './frontend/component/mail/mail.component';
import { ProdutcsComponent } from './frontend/component/produtcs/produtcs.component';
import { SingleComponent } from './frontend/component/single/single.component';
import { GalleryComponent } from './frontend/component/gallery/gallery.component';
import { AboutComponent } from './frontend/component/about/about.component';


import { HashLocationStrategy , LocationStrategy } from '@angular/common';



const routes: Routes =[
    { path: 'frontend', component: FrontendComponent, children:[
      { path: '', component: FrontendHomeComponent, outlet: 'frontend' },
      { path: 'home', component: FrontendHomeComponent, outlet: 'frontend' },
      { path: 'mail', component: MailComponent, outlet: 'frontend' },
      { path: 'products', component: ProdutcsComponent, outlet: 'frontend' },
      { path: 'single', component: SingleComponent, outlet: 'frontend' },
      { path: 'gallery', component: GalleryComponent, outlet: 'frontend' },
      { path: 'about', component: AboutComponent, outlet: 'frontend' },
      { path: '', redirectTo: 'home', pathMatch: 'full' }
    ] },
    { path: 'backend',  component: BackendComponent,  children:[
        { path: '', component: HomeComponent, outlet: 'backend' },
        { path: 'home', component: HomeComponent, outlet: 'backend' },
        { path: 'user-profile',     component: ProfileComponent, outlet: 'backend' },
        { path: 'register',           component: SignupComponent, outlet: 'backend' },
        { path: 'landing',          component: LandingComponent, outlet: 'backend' },
        { path: 'login',          component: LoginComponent, outlet: 'backend' },
        { path: '', redirectTo: 'home', pathMatch: 'full' }
      ]
    },
    { path: '', redirectTo: 'frontend', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
})
export class AppRoutingModule { }
