import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// Navbar and footer 
import { NavbarComponent } from './component/navbar/navbar.component';
import { FooterComponent } from './component/footer/footer.component';
import { FrontendComponent } from './frontend.component';
import { ProdutcsComponent } from './component/produtcs/produtcs.component';
import { GalleryModule } from './component/gallery/gallery.module';
import { FrontendHomeComponent } from './component/home/home.component';
import { AboutComponent } from './component/about/about.component';
import { MailComponent } from './component/mail/mail.component';
import { SingleComponent } from './component/single/single.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    FrontendComponent,
    NavbarComponent,
    FooterComponent,
    ProdutcsComponent,
    FrontendHomeComponent,
    AboutComponent,
    MailComponent,
    SingleComponent,
  ],
  imports: [
      CommonModule,
      BrowserModule,
      NgbModule,
      HttpClientModule,
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }),
      FormsModule,
      RouterModule,
      GalleryModule
  ],
  providers: [HttpClient],
  bootstrap: [FrontendComponent]
})
export class FrontendModule { }
