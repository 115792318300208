import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frontend.component.gallery',
  templateUrl: 'gallery.component.html',
  styleUrls: ['gallery.component.css']
})
export class GalleryComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
    
  }

}
