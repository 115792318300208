import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-frontend',
  templateUrl: './frontend.component.html',
  styleUrls: ['./frontend.component.css']
})
export class FrontendComponent implements OnInit {

  constructor(private translateService: TranslateService,  @Inject(DOCUMENT,) private document: any) {
    this.translateService.setDefaultLang('id');
    this.translateService.use(localStorage.getItem('lang')||'id');
  }

  ngOnInit(): void {
    this.loadTheme();
  }

  loadTheme(){
    // <head>
    //     <link rel="strylsheet" type="text/css" href="argon-style.css">
    // </head>

    const headEl = this.document.getElementsByTagName('head')[0];

    const styles = [
      'frontend-bootstrap-module',
      'frontend-countdown-module',
      'frontend-animate-module',
      'frontend-style-module'
    ];

    styles.forEach((style)=>{
      let newLinkEl = this.document.createElement('link');
      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = style+'.css';
      headEl.appendChild(newLinkEl);
    });
}

}
