import { Component, OnInit } from '@angular/core';
import '../../../../assets/js/uisearch.js';

declare var UISearch : any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  lang;

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') || 'id';
    new UISearch( document.getElementById( 'sb-search' ) );
  }

  changeLang(lang){
    localStorage.setItem('lang',lang);
    window.location.reload();
  }
}
