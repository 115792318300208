import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

// Import section module
import { SectionsModule } from './component/sections/sections.module';
import { HomeModule } from './component/home/home.module';

// Navbar and footer 
import { NavbarComponent } from './component/shared/navbar/navbar.component';
import { FooterComponent } from './component/shared/footer/footer.component';
import { BackendComponent } from './backend.component';
import { SignupComponent } from './component/signup/signup.component';
import { HomeComponent } from './component/home/home.component';

import { LandingComponent } from './component/landing/landing.component';


@NgModule({
  imports: [
      CommonModule,
      BrowserModule,
      NgbModule,
      FormsModule,
      RouterModule,
      SectionsModule,
      HomeModule
  ],
  declarations: [
    BackendComponent,
    NavbarComponent,
    FooterComponent,
  ],
  providers: [],
  bootstrap: [BackendComponent],
  exports: [
    BackendComponent
  ]
})
export class BackendModule { }
